<div class="reset-password">
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 h-screen">
    <div
      class="
        col-span-1
        md:col-span-1
        lg:col-span-2
        p-10
        md:block
        bg-gray-100
        hidden
      "
    >
      <div class="flex justify-between items-center">
        <img src="@/assets/logo.jpg" alt="" class="w-14 h-14 rounded-full" />
      </div>
      <div class="flex items-center justify-center">
        <img src="@/assets/forgot-password.svg" alt="photo" class="p-10" />
      </div>
    </div>

    <div class="p-10 md:p-14">
      <!-- <div class="flex justify-end">
        <changeLanguage />
      </div> -->
      <div class="h-full flex items-center justify-center">
        <div>
          <h3 class="font-500 text-xl mb-2">{{$t("auth.otp.auth")}}</h3>
          <p class="enter-character text-base font-400 mb-5">
            {{$t("auth.otp.character")}}
          </p>
          <div class="otp-code grid grid-cols-4 gap-10">
            <div>
              <input
                type="number"
                maxlength="1"
                max="9"
                min="0"
                id="one"
                v-model="otp1"
                @click="clickEvent('one')"
              />
            </div>
            <div>
              <input
                type="number"
                maxlength="1"
                max="9"
                min="0"
                id="two"
                v-model="otp2"
                @click="clickEvent('two')"
              />
            </div>
            <div>
              <input
                type="number"
                maxlength="1"
                max="9"
                min="0"
                id="three"
                v-model="otp3"
                @click="clickEvent('three')"
              />
            </div>
            <div>
              <input
                type="number"
                v-model="otp4"
                maxlength="1"
                max="9"
                min="0"
                id="four"
                @click="clickEvent('four')"
              />
            </div>
          </div>
          <div class="otp-coundown mt-3">
            <span v-if="coundown > 0">
              {{$t("auth.otp.sendback")}}:
              <b>{{ coundown }}</b> {{$t("auth.otp.second")}}
            </span>
            <span v-else>
              {{$t("auth.otp.notreceived")}}:
              <span
                @click="againOtp"
                class="text-blue-400 hover:text-blue-700 cursor-pointer"
              >
                {{$t("auth.otp.sendto")}}
              </span>
            </span>
          </div>
          <div class="mt-6">
            <div class="signin mt-4">
              <router-link :to="{name: 'resetPassword'}">
                <button
                  class="
                    bg-blue-500
                    hover:bg-blue-700
                    text-white
                    font-bold
                    py-2
                    px-4
                    rounded
                  "
                  style="width: 100%"
                >
                  {{$t("auth.otp.confirm")}}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
