import changeLanguage from "@/components/changeLanguage.vue";

export default {
  name: "resetPassword",
  components: {
    changeLanguage,
  },
  data() {
    return {
      otp1: null,
      otp2: null,
      otp3: null,
      otp4: null,
      coundown: 5,
    };
  },
  created() {
    this.startCoundown();
  },
  watch: {
    otp1() {
      document.getElementById("two").select();
    },
    otp2() {
      document.getElementById("three").select();
    },
    otp3() {
      document.getElementById("four").select();
    },
    otp4() {
      if (this.otp4 == null) {
        document.getElementById("one").select();
      }
    },
  },
  methods: {
    clickEvent(last) {
      document.getElementById(last).select();
    },
    startCoundown() {
      if (this.coundown > 0) {
        setTimeout(() => {
          this.coundown -= 1;
          this.startCoundown();
        }, 1000);
      }
    },
    againOtp() {
      this.coundown = 5;
      this.otp1 = null;
      this.otp2 = null;
      this.otp3 = null;
      this.otp4 = null;
      document.getElementById("one").select();
      this.startCoundown();
    },
  },
};
